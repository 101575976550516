module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[],"excludePattern":"(login|logout|register|admin)"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Linktree","short_name":"starter","start_url":"/","display":"minimal-ui","background_color":"#F3F3F1","theme_color":"#F3F3F1","icon":"src/images/favicon.png","cache_busting_mode":"none","include_favicon":false,"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"http://linktreestg.wpengine.com/graphql","schema":{"timeout":60000,"perPage":50,"requestConcurrency":15,"previewRequestConcurrency":5,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"html":{"useGatsbyImage":false,"createStaticFiles":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"type":{"MediaItem":{"createFileNodes":false,"placeholderSizeName":"gatsby-image-placeholder","lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":5000,"hardCacheData":false},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-datadog/gatsby-browser.js'),
      options: {"plugins":[],"site":"datadoghq.com","sampleRate":1,"enabled":true,"rum":{"applicationId":"a2cadcfb-2c11-444d-b54e-f9d775bd3b8a","clientToken":"pub3c5384c350f7b86c67a1cba0b315ee9d","env":"development","sampleRate":1},"logs":{"clientToken":"pub3c5384c350f7b86c67a1cba0b315ee9d","env":"development"}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
